import { Button as ButtonPrimitive } from '@radix-ui/themes'
import { forwardRef } from 'react'
import { mergeProps } from '../component-utils'
import styles from './button.module.css'
import * as Disclosure from './disclosure'
import { Circular as Loader } from './loader'

export { Button }

const Button = forwardRef(
  /** @param {import('@radix-ui/themes').ButtonProps} props */
  function Button({ loading, children, disabled, ...props }, ref) {
    return (
      <ButtonPrimitive
        disabled={loading || disabled}
        ref={ref}
        {...mergeProps(props, {
          className: styles.root,
        })}
      >
        {loading !== undefined && (
          <Disclosure.Root
            className={styles.loaderDisclosure}
            open={loading}
            orientation="horizontal"
          >
            <Disclosure.Content>
              <Loader className={styles.loader} />
            </Disclosure.Content>
          </Disclosure.Root>
        )}
        {children}
      </ButtonPrimitive>
    )
  },
)

import { resolveToken, fetcher, HTTPMethods } from './core'

export { callAPI, HTTPMethods }

async function callAPI(
  path,
  {
    session,
    token = session?.accessToken,
    headers = {},
    body = undefined,
    method = 'GET',
    API_BASE = process?.env?.API_BASE ?? // eslint-disable-line no-undef -- eslint config error
      process?.env?.NEXT_PUBLIC_API_BASE ?? // eslint-disable-line no-undef -- eslint config error
      'https://api.synqly.com',
    ...options
  } = {},
) {
  const url = `${API_BASE}/${path.replace(/^\//, '')}`

  const accessToken = resolveToken(token ?? session?.accessToken)
  return fetcher(url, accessToken, { ...options, method, headers, body })
}

import { Dialog, Flex } from '@radix-ui/themes'
import { withProps } from '../component-utils.jsx'
import styles from './dialog.module.css'

export { Root, Trigger, Content, Title, Description, Close, Actions }

const Root = withProps(Dialog.Root, {
  className: styles.root,
})

const Content = withProps(Dialog.Content, {
  className: styles.content,
  size: '4',
})

const Title = withProps(Dialog.Title, {
  className: styles.title,
})

const Description = withProps(Dialog.Description, {
  className: styles.description,
})

const Trigger = withProps(Dialog.Trigger, {
  className: styles.trigger,
})

const Close = withProps(Dialog.Close, {
  color: 'gray',
  variant: 'soft',
  className: styles.close,
})

const Actions = withProps(Flex, {
  className: styles.actions,
  justify: 'end',
  gap: '2',
})
